// 路由的配置信息
// 引入路由页面
// import Home from "@/pages/home";
// import About from "@/pages/about";
// import mapPage from "@/pages/mapPage";
// import mapVRPage from "@/pages/mapVRPage";

export default [
    // 重定向(当项目跑起来的时候，访问/，立马让它定向到首页)
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: "/home",
        component: () => import('@/pages/home.vue'),
        meta: {show: true}
    },
    {
        path: "/about",
        component: () => import('@/pages/about.vue'),
        meta: {show: true}
    },
    {
        path: "/map",
        component: () => import('@/pages/mapPage.vue'),
        meta: {show: true}
    },
    {
        path: "/vrmap",
        component: () => import('@/pages/mapVRPage.vue'),
        meta: {show: true}
    }
]

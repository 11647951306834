import Vue from 'vue'
import App from './App.vue'
import ElementUI from  'element-ui'
// 引入css文件
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/main.css'
import router from "@/router";

// Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  el:'#app',
  // 注册路由:底下的写法kv一致省略v【router小写的】
  // 注册路由信息：当这里书写router的时候，组件身上都拥有$route,$router属性
  router,
}).$mount('#app')

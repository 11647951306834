// 配置路由的地方
import Vue from "vue";
import VueRouter from "vue-router";
// 引入路由配置
import routes from "@/router/routes";
// 使用插件
Vue.use(VueRouter)

// 先把VueRouter原型对象的push，先保存一份
let originPush = VueRouter.prototype.push
let originPlace = VueRouter.prototype.replace
// 重写push与replace方法
// 第一个参数：告诉原来push方法，你往哪里传（传递哪些参数）
VueRouter.prototype.push = function (location, resolve, reject) {
    // 这里的this为VueRouter
    if (resolve && reject) {
        // originPush不能直接调，因为它的原型是window，要使用call属性，要保证为Vuerouter实例!
        // call || apply 相同点：都可以调用函数一次，都可以篡改函数的上下文一次   不同点：传递参数，call传递多个参数用逗号隔开，apply传递数组
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => {
        }, () => {
        })
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originPlace.call(this, location, resolve, reject)
    } else {
        originPlace.call(this, location, () => {
        }, () => {
        })
    }
}

// 配置路由
let router =  new VueRouter({
    // 配置路由
    routes,
    // 路由滚动行为
    scrollBehavior(to, from, savedPosition) {
        // 始终滚动到顶部
        return {y: 0}
    }
})

export default router